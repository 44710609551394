exports.components = {
  "component---src-components-tools-mesa-config-js": () => import("./../../../src/components/tools/mesa-config.js" /* webpackChunkName: "component---src-components-tools-mesa-config-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-index-jsx": () => import("./../../../src/templates/article_index.jsx" /* webpackChunkName: "component---src-templates-article-index-jsx" */),
  "component---src-templates-article-jsx-content-file-path-articles-3-d-printed-moulds-for-drills-and-endmills-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/3D Printed Moulds for Drills and Endmills.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-3-d-printed-moulds-for-drills-and-endmills-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-anleitung-cnc-retrofit-dokumentieren-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Anleitung CNC Retrofit dokumentieren.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-anleitung-cnc-retrofit-dokumentieren-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-anleitung-linux-cnc-autologin-und-autostart-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Anleitung LinuxCNC Autologin und Autostart.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-anleitung-linux-cnc-autologin-und-autostart-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-anleitung-vorschub-und-drehzahl-fur-eine-cnc-frasmaschine-berechnen-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Anleitung Vorschub und Drehzahl für eine CNC Fräsmaschine berechnen.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-anleitung-vorschub-und-drehzahl-fur-eine-cnc-frasmaschine-berechnen-mdx" */),
  "component---src-templates-article-jsx-content-file-path-articles-anleitung-zum-retrofit-einer-cnc-frasmaschine-mit-linux-cnc-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Anleitung zum Retrofit einer CNC-Fräsmaschine mit LinuxCNC.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-anleitung-zum-retrofit-einer-cnc-frasmaschine-mit-linux-cnc-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-der-ultimative-mesa-karten-guide-fur-linux-cnc-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Der ultimative Mesa Karten Guide für LinuxCNC.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-der-ultimative-mesa-karten-guide-fur-linux-cnc-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-die-6-gangigsten-beschichtungen-fur-fraser-und-bohrer-farben-vorteile-und-einsatzbereiche-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Die 6 gängigsten Beschichtungen für Fräser und Bohrer - Farben, Vorteile und Einsatzbereiche.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-die-6-gangigsten-beschichtungen-fur-fraser-und-bohrer-farben-vorteile-und-einsatzbereiche-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-how-a-cnc-controller-works-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/How a CNC controller works.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-how-a-cnc-controller-works-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-how-to-retrofit-an-industrial-cnc-milling-machine-with-linux-cnc-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/How to retrofit an industrial CNC milling machine with LinuxCNC.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-how-to-retrofit-an-industrial-cnc-milling-machine-with-linux-cnc-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-installing-linux-cnc-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Installing LinuxCNC.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-installing-linux-cnc-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-and-its-competition-crushing-features-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/LinuxCNC and its competition-crushing features.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-and-its-competition-crushing-features-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-hardware-welche-p-cs-konnen-verwendet-werden-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/LinuxCNC Hardware Welche PCs können verwendet werden.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-hardware-welche-p-cs-konnen-verwendet-werden-mdx" */),
  "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-hardware-which-pc-can-be-used-mdx": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/LinuxCNC Hardware Which PC Can Be Used.mdx" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-hardware-which-pc-can-be-used-mdx" */),
  "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-installieren-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/LinuxCNC Installieren.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-linux-cnc-installieren-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-the-ultimate-mesa-card-guide-for-linux-cnc-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/The ultimate Mesa Card Guide for LinuxCNC.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-the-ultimate-mesa-card-guide-for-linux-cnc-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-tutorial-linux-autologin-and-autostart-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Tutorial Linux Autologin and Autostart.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-tutorial-linux-autologin-and-autostart-md" */),
  "component---src-templates-article-jsx-content-file-path-articles-why-realtime-matters-in-cnc-machining-controllers-md": () => import("./../../../src/templates/article.jsx?__contentFilePath=/opt/build/repo/articles/Why realtime matters in CNC machining controllers.md" /* webpackChunkName: "component---src-templates-article-jsx-content-file-path-articles-why-realtime-matters-in-cnc-machining-controllers-md" */),
  "component---src-templates-tool-index-jsx": () => import("./../../../src/templates/tool_index.jsx" /* webpackChunkName: "component---src-templates-tool-index-jsx" */)
}

